'use client'

import { ErrorFallback } from '@/components/organisms/site/ErrorFallback'

export default function Error({
  error,
  reset
}: {
  error: Error
  reset: () => void
}) {
  return <ErrorFallback error={error} resetErrorBoundary={reset} />
}
